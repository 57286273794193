<template>
  <v-container>
    <div class="mb-4">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" v-on="on" @click="back" class="noprint mr-3">
            <v-icon>
              mdi-arrow-left
            </v-icon>
          </v-btn>
        </template>
        <span>Back</span>
      </v-tooltip>
      <v-tooltip bottom content-class="noprint">
        <template v-slot:activator="{ on }">
          <v-btn color="success" v-on="on" @click="print" class="noprint mr-2">
            <v-icon>
              mdi-file-pdf-box
            </v-icon>
          </v-btn>
        </template>
        <span>Print Pdf</span>
      </v-tooltip>
      <v-tooltip bottom content-class="noprint">
        <template v-slot:activator="{ on }">
          <v-btn color="success" v-on="on" @click="excel" class="noprint">
            <v-icon>
              mdi-file-excel
            </v-icon>
          </v-btn>
        </template>
        <span>Print Excel</span>
      </v-tooltip>
    </div>
    <div>
      <v-data-table
        :headers="headers"
        :items="chartOfAccounts"
        dense
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item.no="{ item }">
          {{ index(item) + 1 }}
        </template>
        <template v-slot:item.openingBalance="{ item }">
          {{ formatPrice(item.balance) }}
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { mapState } from "vuex";
import fileDownload from "js-file-download";

export default {
  name: "print-chart-of-account",
  props: {
    size: Number,
  },
  computed: {
    ...mapState("chartOfAccount", ["chartOfAccounts"]),
  },
  data() {
    return {
      headers: [
        {
          id: 1,
          text: "No",
          align: "center",
          sortable: false,
          value: "no",
          divider: true,
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 2,
          text: "Account No",
          value: "accountNumber",
          sortable: false,
          divider: true,
          align: "center",
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 3,
          text: "Name",
          value: "accountName",
          sortable: false,
          divider: true,
          align: "start",
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 4,
          text: "Currency",
          value: "currency",
          sortable: false,
          divider: true,
          align: "center",
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 5,
          text: "Pointer",
          value: "pointer",
          sortable: false,
          divider: true,
          align: "center",
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 6,
          text: "Nature",
          value: "nature",
          sortable: false,
          divider: true,
          align: "center",
          class: "light-blue lighten-2 font-weight-black",
        },
        {
          id: 7,
          text: "Balance",
          value: "openingBalance",
          sortable: false,
          divider: true,
          align: "start",
          class: "light-blue lighten-2 font-weight-black",
        },
      ],
    };
  },
  methods: {
    async initialize() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("chartOfAccount/getAll", { size: this.size })
        .then(() => this.$store.commit("SET_LOADER", false))
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    index(item) {
      return this.chartOfAccounts.map(x => x).indexOf(item);
    },
    back() {
      this.$router.go(-1);
    },
    print() {
      window.print();
    },
    async excel() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("chartOfAccount/printExcel", { size: this.size })
        .then(response => {
          fileDownload(response.data, `report-chart-of-account.xlsx`);
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped>
@media print {
  .noprint {
    display: none;
  }
}
</style>
